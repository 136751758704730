.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.loader span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.loader span:nth-child(1) {
  transform: rotate(0deg);
}

.loader span:nth-child(3) {
  transform: rotate(180deg);
}

.loader span:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: black;
  transform: scaleX(0);
  animation: animate 2s linear infinite;
}
.loader span:nth-child(1):before {
  animation-delay: 0s;
}

.loader span:nth-child(3):before {
  animation-delay: 0s;
}

@keyframes animate {
  0% {
    transform: scaleX(0);
  }
  25% {
    transform: scaleX(1);
  }
  25.00001% {
    /* transform-origin: right; */
  }
  50%,
  100% {
    /* transform: scaleX(0); */
    /* transform-origin: right; */
  }
}
