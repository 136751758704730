.loginfb {
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e1e2e5;
  width: 100%;
  background: #f5f5f7;
  text-align: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 551;
  cursor: pointer;
}

.loginfb:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

.loginfb svg {
  display: revert;
  margin-right: 10px;
}
